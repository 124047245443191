import Localization from '~/constants/localization/localization'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      [Localization.welcome]: 'Welcome',
      [Localization.language]: 'English',
      [Localization.stocktakingIntro]: 'Easy as just print invoices',
      [Localization.selectALanguage]: 'Select Language',
      [Localization.customer]: 'Customer',
      [Localization.customers]: 'Customers',
      [Localization.product]: 'Product',
      [Localization.products]: 'Products',
      [Localization.company]: 'Company',
      [Localization.companies]: 'Companies',
      [Localization.invoices]: 'invoices',
      [Localization.add]: 'Add',
      [Localization.edit]: 'Edit',
      [Localization.delete]: 'Delete',
      [Localization.areYouSure]: 'Are you sure',
      [Localization.dashboard]: 'Dashboard',
      [Localization.brand]: 'Brand',
      [Localization.brands]: 'Brands',
      [Localization.category]: 'Category',
      [Localization.categories]: 'Categories',
      [Localization.users]: 'Users',
      [Localization.invoicesDashboard]: 'Get your invoices done in no seconds, keep tracking of your invoices at every change that the invoice can get',
      [Localization.tracking]: 'Tracking',
      [Localization.trackDashboard]: 'Get in touch with your products, set marks and see how your sales are doing',
      [Localization.english]: 'English',
      [Localization.spanish]: 'Spanish',
      [Localization.french]: 'French',
      [Localization.attributes]: 'Attributes',
      [Localization.attribute]: 'Attribute',
      [Localization.warehouse]: 'Warehouse',
      [Localization.warehouses]: 'Warehouses',
      [Localization.vendor]: 'Vendor',
      [Localization.vendors]: 'Vendors',
      [Localization.dayOfCutOff]: 'Day of cut off',
      [Localization.numberOfPayments]: 'Number of payments',
      [Localization.interestToBeAppliedWhenLatePayment]: 'Interest to Be Applied When Late Payment',
      [Localization.interestIsPercentage]: 'Interest is percentage',
      [Localization.applyInterestAutomatically]: 'Apply interest automatically',
      [Localization.dayCutOffNotSetWarning]: 'If you dont set a days of cut off, there it will not be a track for the payment of this invoice',
      [Localization.warning]: 'Warning',
      [Localization.every]: 'Every',
      [Localization.dayss]: 'Day(s)',
      [Localization.noDaysOfCutOff]: 'No day of cut off',
      [Localization.no]: 'No',
      [Localization.yes]: 'Yes',
      [Localization.paymentInInstallments]: 'Payment installments',
      [Localization.isNumberOfPaymentOrPaymentInstallments]: 'Go by payment numbers',
      [Localization.thisValueMustBeGreaterThanZero]: 'This value must be greater than zero',
      [Localization.penaltyToBeAppliedWhenLatePayment]: 'Penalty to be applied when late payment',
      [Localization.thisValueMustBeGreaterOrEqualZero]: 'This Value must be greater or equal to zero',
      [Localization.start]: 'Start',
      [Localization.within]: 'Within',
      [Localization.of]: 'of',
      [Localization.startThePaymentsWithinNDays]: 'Start the payments within',
      [Localization.penaltyToBeAppliedWhenLatePaymentIsPercentage]: 'Penalty is percentage',
      [Localization.noSettled]: 'no settled',
      [Localization.thisValueMustBeGreaterOrEqual]: 'This value must be greater or equal',
      [Localization.saveAndConfirmInvoice]: 'Save and confirm invoice information',
      [Localization.justDeactivateTheInvoicePaymentConfiguration]: 'Just deactivate invoice payment configuration',
      [Localization.or]: 'Or',
      [Localization.isInvoiceAlreadyPaid]: 'is Invoice already paid?',
      [Localization.everythingSeemsFineHere]: 'Everything seems to be fine here',
      [Localization.pleaseReviewYourInvoicePaymentConfigurationSettings]: 'Please review your invoice payment configuration settings',
      [Localization.pleaseReviewYourTotalInvoice]: 'Please review your total invoice',
      [Localization.yourInvoiceHasATotalOfZeroToHaveAPaymentPlanConfigurationMustBeGreaterThanZero]: 'Your invoice has a total of $0.00 to have a payment configuration plan, you must ensure that your invoice has a total greater than $0.00',
      [Localization.createdFrom]: 'Created From',
      [Localization.isPaid]: 'Is paid',
      [Localization.isNotPaid]: 'Not paid',
      [Localization.invoice]: 'Invoice',
      [Localization.withInternalId]: 'with internal id',
      [Localization.timeline]: 'Timeline',
      [Localization.timelineOfPayments]: 'Timeline of payments',
      [Localization.installmentInvoice]: 'Installment Invoice',
      [Localization.paymentIsOverdue]: 'Payment is overdue',
      [Localization.paymentsDone]: 'Payments done',
      [Localization.paymentsHistory]: 'Payments history',
      [Localization.payment]: 'Payment',
      [Localization.penalty]: 'Penalty',
      [Localization.totalOfDaysLeftToPay]: 'Total of days left to pay',
      [Localization.paymentIsOverdueBy]: 'Payment is overdue by',
      [Localization.accumulatedInterests]: 'Accumulated interests',
      [Localization.totalToPay]: 'Total to pay',
      [Localization.baseTotal]: 'Base total',
      [Localization.addAPayment]: 'Add a payment',
      [Localization.didPaymentWasOverdue]: 'Did payment was overdue',
      [Localization.daysThatInstallmentInvoiceWasOverdue]: 'Days that installment invoice was overdue',
      [Localization.penaltyApplied]: 'Penalty applied',
      [Localization.appliedInterests]: 'Applied interests',
      [Localization.wasPaidAt]: 'Was paid at',
      [Localization.wasPaidWithPayment]: 'Was paid with payment',
      [Localization.paymentNumber]: 'Payment number',
      [Localization.didPaymentPaidAnOverdueInstallment]: 'Did payment paid an overdue installment',
      [Localization.paidTheNextInstallmentPayments]: 'Paid the next installment payments',
      [Localization.makeAPayment]: 'Make a payment',
      [Localization.pay]: 'Pay',
      [Localization.forgotPassword]: 'Forgot password',
      [Localization.weAlreadySentYouAnEmailWithTheCode]: 'we Already sent you an email (if the email exist in our registers) with the code to',
      [Localization.pleaseVerifyYourEmail]: 'Please verify your email',
      [Localization.weAreValidatingYourCode]: 'We are validating your code',
      [Localization.enterTheCode]: 'Enter the code',
      [Localization.weAreSoSorry]: 'We are so sorry',
      [Localization.yourCodeAreNotValid]: 'Your code are not valid',
      [Localization.pleaseLoginWithYourNewCredentials]: 'Please login with your new credentials',
      [Localization.yourCodeWasAlreadyUsed]: 'Your code was already used, resend and email with a new code',
      [Localization.email]: 'Email',
      [Localization.password]: 'Password',
      [Localization.login]: 'Login',
      [Localization.setANewPassword]: 'Set a new password',
      [Localization.thePasswordsDoesntMatch]: 'The passwords doesnt match',
      [Localization.iForgotMyPassword]: 'I forgot my password',
      [Localization.useYourCredentialsToStartANewDay]: 'Use your credentials to start a new day',
      [Localization.wrongCredentials]: 'Wrong credentials',
      [Localization.sendMyAndEmailToResetMyPassword]: 'Send me an email to reset my password',
      [Localization.goToLogin]: 'Go to login',
      [Localization.changePassword]: 'Change Password',
      [Localization.reenterPassword]: 'Reenter password',
      [Localization.passwordIsRequired]: 'Password is required',
      [Localization.thisFieldIsRequired]: 'This field is required',
      [Localization.thisFieldMustBeLength]: 'This field must be length',
      [Localization.characterss]: 'character(s)',
      [Localization.somethingWentWrong]: 'Something went wrong',
      [Localization.passwordChangedSuccessfully]: 'Password changed successfully',
      [Localization.goLoginWithYourNewCredentials]: 'Go login with your new credentials',
      [Localization.customerManagement]: 'Customer Management',
      [Localization.customerCompanies]: 'Customer Companies',
      [Localization.createdAtTo]: 'Created at to',
      [Localization.searchBy]: 'Search by',
      [Localization.name]: 'Name',
      [Localization.addressInformation]: 'Address Information',
      [Localization.contactInformation]: 'Contact Information',
      [Localization.totalOfInvoices]: 'Total of invoices',
      [Localization.unpaidInvoices]: 'Unpaid invoices',
      [Localization.fullAddress]: 'Full Address',
      [Localization.zipCode]: 'Zip Code',
      [Localization.line2]: 'Line 2',
      [Localization.phoneNumber]: 'Phone Number',
      [Localization.secondPhoneNumber]: 'Second Phone Number',
      [Localization.cellPhone]: 'Cell Phone',
      [Localization.paid]: 'Paid',
      [Localization.all]: 'All',
      [Localization.notPaid]: 'Not Paid',
      [Localization.isFlexible]: 'Is strict payment in installments',
      [Localization.productsManagement]: 'Product Management',
      [Localization.payments]: 'Payments',
      [Localization.paidDateFrom]: 'Paid date from',
      [Localization.paidDateTo]: 'Paid date to',
      [Localization.installmentPayments]: 'Installment Payments',
    },
    fr: {
      [Localization.welcome]: 'Bienvenue',
      [Localization.language]: 'French',
      [Localization.stocktakingIntro]: "Facile, il suffit d'imprimer des factures",
      [Localization.selectALanguage]: 'sélectionnez une langue',
      [Localization.customer]: 'Client',
      [Localization.customers]: 'Clients',
      [Localization.product]: 'Produit',
      [Localization.products]: 'Products',
      [Localization.company]: 'Company',
      [Localization.companies]: 'Entreprises',
      [Localization.invoices]: 'factures',
      [Localization.add]: 'Add',
      [Localization.edit]: 'Edit',
      [Localization.delete]: 'Delete',
      [Localization.areYouSure]: 'Are you sure',
      [Localization.dashboard]: 'Tableau de bord',
      [Localization.brand]: 'Marque',
      [Localization.brands]: 'Marques',
      [Localization.category]: 'catégorie',
      [Localization.categories]: 'catégories',
      [Localization.users]: 'Utilisatrices',
      [Localization.invoicesDashboard]: 'Réglez vos factures en un rien de temps, gardez le suivi de vos factures à chaque modification apportée à la facture.',
      [Localization.tracking]: 'seguimiento',
      [Localization.trackDashboard]: 'Entrez en contact avec vos produits, fixez des repères et voyez comment évoluent vos ventes1',
      [Localization.english]: 'Anglaise',
      [Localization.spanish]: 'Espagnole',
      [Localization.french]: 'Français',
      [Localization.attributes]: 'les Attributs',
      [Localization.attribute]: 'Attributs',
      [Localization.warehouse]: 'entrepôt',
      [Localization.warehouses]: 'entrepôts',
      [Localization.vendor]: 'fournisseuse',
      [Localization.vendors]: 'vendeuses',
      [Localization.dayOfCutOff]: 'Jours limites de paiement',
      [Localization.numberOfPayments]: 'montant des paiements',
      [Localization.interestToBeAppliedWhenLatePayment]: 'Intérêts à appliquer en cas de retard de paiement',
      [Localization.interestIsPercentage]: "L'intérêt est en pourcentage",
      [Localization.applyInterestAutomatically]: 'Appliquer automatiquement les intérêts',
      [Localization.dayCutOffNotSetWarning]: "Si vous ne fixez pas de jours limites, il n'y aura pas de piste pour le paiement de cette facture.",
      [Localization.warning]: 'Avertissement',
      [Localization.every]: 'Chaque',
      [Localization.dayss]: 'Jour(s)',
      [Localization.noDaysOfCutOff]: 'pas de jour de coupure',
      [Localization.no]: 'No',
      [Localization.yes]: 'Yes',
      [Localization.paymentInInstallments]: 'versements échelonnés',
      [Localization.isNumberOfPaymentOrPaymentInstallments]: 'Go by payment numbers',
      [Localization.thisValueMustBeGreaterThanZero]: 'Este valor debe de ser mayor a cero',
      [Localization.penaltyToBeAppliedWhenLatePayment]: 'Penalidad a aplicar cuando se presente un pago tardio',
      [Localization.start]: 'Comenzar',
      [Localization.within]: 'Dentro',
      [Localization.of]: 'De',
      [Localization.startThePaymentsWithinNDays]: 'Empezar el plan de pagos dentro de',
      [Localization.penaltyToBeAppliedWhenLatePaymentIsPercentage]: 'Penalidad esta expresado en porcentage',
      [Localization.noSettled]: 'no establecido',
      [Localization.thisValueMustBeGreaterOrEqual]: 'Este valor debe de ser mayor o igual a',
      [Localization.saveAndConfirmInvoice]: 'Almacenar y confirmar factura',
      [Localization.justDeactivateTheInvoicePaymentConfiguration]: 'Simplemente desactiva el plan de pago de la factura',
      [Localization.or]: 'O',
      [Localization.isInvoiceAlreadyPaid]: '¿Esta la factura completamente pagada?',
      [Localization.everythingSeemsFineHere]: 'todo parece estar bien aquí',
      [Localization.pleaseReviewYourInvoicePaymentConfigurationSettings]: 'Revise la configuración de pago de la factura',
      [Localization.pleaseReviewYourTotalInvoice]: 'Veuillez vérifier votre facture totale',
      [Localization.yourInvoiceHasATotalOfZeroToHaveAPaymentPlanConfigurationMustBeGreaterThanZero]: 'Votre facture a un total de $0.00 pour avoir un plan de configuration de paiement, vous devez vous assurer que votre facture a un total supérieur à $0.00',
      [Localization.createdFrom]: 'Créé De',
      [Localization.isPaid]: 'Esta pagada',
      [Localization.isNotPaid]: 'No esta pagada',
      [Localization.invoice]: 'Factura',
      [Localization.withInternalId]: 'avec id interne',
      [Localization.timeline]: 'Chronologie',
      [Localization.timelineOfPayments]: 'Chronologie des paiements',
      [Localization.installmentInvoice]: 'Facture à échéances',
      [Localization.paymentIsOverdue]: 'Le paiement',
      [Localization.paymentsDone]: 'Paiements effectués',
      [Localization.paymentsHistory]: 'Historique des paiements',
      [Localization.payment]: 'Paiement',
      [Localization.penalty]: 'Pénalité',
      [Localization.totalOfDaysLeftToPay]: 'Total des jours restants à payer',
      [Localization.paymentIsOverdueBy]: 'Le paiement',
      [Localization.accumulatedInterests]: 'Intérêts accumulés',
      [Localization.totalToPay]: 'Total à payer',
      [Localization.baseTotal]: 'Total de base',
      [Localization.addAPayment]: 'Ajouter un paiement',
      [Localization.didPaymentWasOverdue]: 'le paiement était-il en retard',
      [Localization.daysThatInstallmentInvoiceWasOverdue]: 'jours pendant lesquels le paiement était en retard',
      [Localization.penaltyApplied]: 'pénalité appliquée',
      [Localization.appliedInterests]: 'intérêts appliqués',
      [Localization.wasPaidAt]: 'a été payé à',
      [Localization.wasPaidWithPayment]: 'a été payé avec paiement',
      [Localization.paymentNumber]: 'numéro de paiement',
      [Localization.didPaymentPaidAnOverdueInstallment]: 'Le paiement a-t-il payé une installation en retard',
      [Localization.paidTheNextInstallmentPayments]: 'payer les prochains acomptes',
      [Localization.makeAPayment]: 'effectuer un paiement',
      [Localization.pay]: 'payer',
      [Localization.forgotPassword]: 'Mot de passe oublié',
      [Localization.weAlreadySentYouAnEmailWithTheCode]: 'nous vous avons déjà envoyé un e-mail avec le code',
      [Localization.pleaseVerifyYourEmail]: 'veuillez vérifier votre email',
      [Localization.weAreValidatingYourCode]: 'nous validons votre code',
      [Localization.enterTheCode]: 'Entrer le code',
      [Localization.weAreSoSorry]: 'Nous sommes vraiment navrés',
      [Localization.yourCodeAreNotValid]: "votre code n'est pas valide",
      [Localization.pleaseLoginWithYourNewCredentials]: 'veuillez vous connecter avec vos nouveaux identifiants',
      [Localization.yourCodeWasAlreadyUsed]: 'Votre code a déjà été utilisé, renvoyez-le et envoyez-le par e-mail avec un nouveau code',
      [Localization.email]: 'Email',
      [Localization.password]: 'mot de passe',
      [Localization.login]: 'se connecter',
      [Localization.setANewPassword]: 'définir un nouveau mot de passe',
      [Localization.thePasswordsDoesntMatch]: 'Les mots de passe ne correspondent pas',
      [Localization.iForgotMyPassword]: "J'ai oublié mon mot de passe",
      [Localization.useYourCredentialsToStartANewDay]: 'Utilisez vos identifiants pour commencer une nouvelle journée',
      [Localization.wrongCredentials]: "Mauvaises informations d'identification",
      [Localization.sendMyAndEmailToResetMyPassword]: 'Envoyez mon e-mail pour réinitialiser mon mot de passe',
      [Localization.goToLogin]: 'Aller à la connexion',
      [Localization.changePassword]: 'Changer le mot de passe',
      [Localization.reenterPassword]: 'Re-entrez le mot de passe',
      [Localization.passwordIsRequired]: 'Le mot de passe est requis',
      [Localization.thisFieldIsRequired]: 'Ce champ est requis',
      [Localization.thisFieldMustBeLength]: 'Ce champ doit avoir une longueur de',
      [Localization.characterss]: 'caractère(s)',
      [Localization.somethingWentWrong]: "Quelque chose s'est mal passé",
      [Localization.passwordChangedSuccessfully]: 'Mot de passe changé avec succès',
      [Localization.goLoginWithYourNewCredentials]: 'Connectez-vous avec vos nouveaux identifiants',
      [Localization.customerManagement]: 'Gestion des clients',
      [Localization.customerCompanies]: 'Entreprises clientes',
      [Localization.createdAtTo]: 'Créé à partir de',
      [Localization.searchBy]: 'Rechercher par',
      [Localization.name]: 'Nom',
      [Localization.addressInformation]: "informations sur l'adresse",
      [Localization.contactInformation]: 'informations de contact',
      [Localization.totalOfInvoices]: 'Total des factures',
      [Localization.unpaidInvoices]: 'Factures impayées',
      [Localization.fullAddress]: 'Adresse complète',
      [Localization.zipCode]: 'Code postal',
      [Localization.line2]: 'Ligne 2',
      [Localization.phoneNumber]: 'Numéro de téléphone',
      [Localization.secondPhoneNumber]: 'Deuxième numéro de téléphone',
      [Localization.cellPhone]: 'Téléphone portable',
      [Localization.paid]: 'payé',
      [Localization.all]: 'Toutes',
      [Localization.notPaid]: 'Non payé',
      [Localization.isFlexible]: 'Est-ce un paiement strict en plusieurs fois',
      [Localization.productsManagement]: 'gestion de produit',
      [Localization.payments]: 'Paiements',
      [Localization.paidDateFrom]: 'Date de paiement à partir de',
      [Localization.paidDateTo]: 'Date de paiement à',
      [Localization.installmentPayments]: 'Paiements échelonnés',
    },
    es: {
      [Localization.welcome]: 'Bienvenido',
      [Localization.language]: 'Español',
      [Localization.stocktakingIntro]: 'Tan fácil como imprimir facturas',
      [Localization.selectALanguage]: 'Selecciona un lenguaje',
      [Localization.customer]: 'Cliente',
      [Localization.customers]: 'Clientes',
      [Localization.product]: 'Producto',
      [Localization.products]: 'Productos',
      [Localization.company]: 'Compañia',
      [Localization.companies]: 'Compañias',
      [Localization.invoices]: 'Facturas',
      [Localization.add]: 'Agregar',
      [Localization.edit]: 'Editar',
      [Localization.delete]: 'Eliminar',
      [Localization.areYouSure]: 'Estas seguro',
      [Localization.dashboard]: 'Inicio',
      [Localization.brand]: 'Marca',
      [Localization.brands]: 'Marcas',
      [Localization.category]: 'category',
      [Localization.categories]: 'Categorias',
      [Localization.users]: 'Usuarios',
      [Localization.invoicesDashboard]: 'Haga sus facturas en cuestión de segundos, realice un seguimiento de sus facturas en cada cambio que pueda sufrir la factura',
      [Localization.tracking]: 'seguimiento',
      [Localization.trackDashboard]: 'Póngase en contacto con sus productos, establezca marcas y vea cómo van sus ventas',
      [Localization.english]: 'Ingles',
      [Localization.spanish]: 'Español',
      [Localization.french]: 'Frances',
      [Localization.attributes]: 'Attributos',
      [Localization.attribute]: 'Attributo',
      [Localization.warehouse]: 'almacén',
      [Localization.warehouses]: 'almacenes',
      [Localization.vendor]: 'Proveedor',
      [Localization.vendors]: 'Proveedores',
      [Localization.dayOfCutOff]: 'Dias de corte de pago',
      [Localization.numberOfPayments]: 'Cantidad de pagos',
      [Localization.interestToBeAppliedWhenLatePayment]: 'interes a aplicar en caso de pago tardio',
      [Localization.interestIsPercentage]: 'El interes esta expresado en porcentaje',
      [Localization.applyInterestAutomatically]: 'Aplicar interes automaticamente',
      [Localization.dayCutOffNotSetWarning]: 'Si no establece un día de corte, no habrá seguimiento para el pago de esta factura.',
      [Localization.warning]: 'Advertencia',
      [Localization.every]: 'Cada',
      [Localization.dayss]: 'Dia(s)',
      [Localization.noDaysOfCutOff]: 'No hay dia de corte',
      [Localization.no]: 'No',
      [Localization.yes]: 'Si',
      [Localization.paymentInInstallments]: 'Cuotas de pago',
      [Localization.isNumberOfPaymentOrPaymentInstallments]: 'Regirse por numeros de pago',
      [Localization.thisValueMustBeGreaterThanZero]: 'Este valor debe de ser mayor a cero',
      [Localization.penaltyToBeAppliedWhenLatePayment]: 'Penalidad a aplicar cuando se presente un pago tardio',
      [Localization.thisValueMustBeGreaterOrEqualZero]: 'Este valor debe de ser mayor o igual a cero',
      [Localization.start]: 'Comenzar',
      [Localization.within]: 'Dentro',
      [Localization.of]: 'De',
      [Localization.startThePaymentsWithinNDays]: 'Empezar el plan de pagos dentro de',
      [Localization.penaltyToBeAppliedWhenLatePaymentIsPercentage]: 'Penalidad esta expresado en porcentaje',
      [Localization.noSettled]: 'no establecido',
      [Localization.thisValueMustBeGreaterOrEqual]: 'Este valor debe de ser mayor o igual a',
      [Localization.saveAndConfirmInvoice]: 'Almacenar y confirmar factura',
      [Localization.justDeactivateTheInvoicePaymentConfiguration]: 'Simplemente desactiva el plan de pago de la factura',
      [Localization.or]: 'O',
      [Localization.isInvoiceAlreadyPaid]: '¿Esta la factura completamente pagada?',
      [Localization.everythingSeemsFineHere]: 'todo parece estar bien aquí',
      [Localization.pleaseReviewYourInvoicePaymentConfigurationSettings]: 'Revise la configuración de pago de la factura',
      [Localization.pleaseReviewYourTotalInvoice]: 'Por favor revisa tu factura total',
      [Localization.yourInvoiceHasATotalOfZeroToHaveAPaymentPlanConfigurationMustBeGreaterThanZero]: 'Tu factura tiene un total de $0.00 para tener un plan de configuración de pago, debes asegurarte de que tu factura tenga un total mayor a $0.00',
      [Localization.createdFrom]: 'Creado desde',
      [Localization.isPaid]: 'Esta pagada',
      [Localization.isNotPaid]: 'No esta pagada',
      [Localization.invoice]: 'Factura',
      [Localization.withInternalId]: 'con id interno',
      [Localization.timeline]: 'Cronograma',
      [Localization.timelineOfPayments]: 'Cronograma de pagos',
      [Localization.installmentInvoice]: 'Factura a plazos',
      [Localization.paymentIsOverdue]: 'El pago esta atrasado',
      [Localization.paymentsDone]: 'Pagos realizados',
      [Localization.paymentsHistory]: 'Historial de pagos',
      [Localization.payment]: 'Pago',
      [Localization.penalty]: 'Penalidad',
      [Localization.totalOfDaysLeftToPay]: 'Total de dias restantes para pagar',
      [Localization.paymentIsOverdueBy]: 'El pago esta atrasado por',
      [Localization.accumulatedInterests]: 'Intereses acumulados',
      [Localization.totalToPay]: 'Total a pagar',
      [Localization.baseTotal]: 'Total base',
      [Localization.addAPayment]: 'Agregar un pago',
      [Localization.didPaymentWasOverdue]: 'El pago estuvo atrasado',
      [Localization.daysThatInstallmentInvoiceWasOverdue]: 'Dias que la factura estuvo atrasada',
      [Localization.penaltyApplied]: 'Penalidad aplicada',
      [Localization.appliedInterests]: 'Intereses aplicados',
      [Localization.wasPaidAt]: 'Fecha de cancelación',
      [Localization.wasPaidWithPayment]: 'Fue pagado con',
      [Localization.paymentNumber]: 'Numero de pago',
      [Localization.didPaymentPaidAnOverdueInstallment]: 'El pago cancelo una cuota atrasada',
      [Localization.paidTheNextInstallmentPayments]: 'Pago las siguientes cuotas',
      [Localization.makeAPayment]: 'Realizar un pago',
      [Localization.pay]: 'Pagar',
      [Localization.forgotPassword]: 'Olvidaste tu contraseña',
      [Localization.weAlreadySentYouAnEmailWithTheCode]: 'Ya te enviamos un correo (si la direccion de correo existe en nuestros registros) con el codigo a',
      [Localization.pleaseVerifyYourEmail]: 'Por favor verifica tu correo',
      [Localization.weAreValidatingYourCode]: 'Estamos validando tu codigo',
      [Localization.enterTheCode]: 'Ingresa el codigo',
      [Localization.weAreSoSorry]: 'Lo sentimos mucho',
      [Localization.yourCodeAreNotValid]: 'Tu codigo no es valido',
      [Localization.pleaseLoginWithYourNewCredentials]: 'Por favor inicia sesion con tus nuevas credenciales',
      [Localization.yourCodeWasAlreadyUsed]: 'Tu codigo ya fue usado, reenvia un correo con un nuevo codigo',
      [Localization.email]: 'Correo electronico',
      [Localization.password]: 'Contraseña',
      [Localization.login]: 'Inicio de sesion',
      [Localization.setANewPassword]: 'Establecer una nueva contraseña',
      [Localization.thePasswordsDoesntMatch]: 'Las contraseñas no coinciden',
      [Localization.iForgotMyPassword]: 'Olvide mi contraseña',
      [Localization.useYourCredentialsToStartANewDay]: 'Usa tus credenciales para comenzar un nuevo dia',
      [Localization.wrongCredentials]: 'Credenciales incorrectas',
      [Localization.sendMyAndEmailToResetMyPassword]: 'Enviame un correo para restablecer mi contraseña',
      [Localization.goToLogin]: 'Ir a inicio de sesion',
      [Localization.changePassword]: 'Cambiar contraseña',
      [Localization.reenterPassword]: 'Reingrese la contraseña',
      [Localization.passwordIsRequired]: 'La contraseña es requerida',
      [Localization.thisFieldIsRequired]: 'Este campo es requerido',
      [Localization.thisFieldMustBeLength]: 'Este campo debe tener una longitud de',
      [Localization.characterss]: 'caracter(es)',
      [Localization.somethingWentWrong]: 'Algo salio mal',
      [Localization.passwordChangedSuccessfully]: 'Contraseña cambiada con exito',
      [Localization.goLoginWithYourNewCredentials]: 'Inicia sesion con tus nuevas credenciales',
      [Localization.customerManagement]: 'Gestion de clientes',
      [Localization.customerCompanies]: 'Empresas clientes',
      [Localization.createdAtTo]: 'Creado desde',
      [Localization.searchBy]: 'Buscar por',
      [Localization.name]: 'Nombre',
      [Localization.addressInformation]: 'Dirección',
      [Localization.contactInformation]: 'Información de contacto',
      [Localization.totalOfInvoices]: 'Total de facturas',
      [Localization.unpaidInvoices]: 'Facturas impagas',
      [Localization.fullAddress]: 'Dirección completa',
      [Localization.zipCode]: 'Código postal',
      [Localization.line2]: 'Linea 2',
      [Localization.phoneNumber]: 'Número de teléfono',
      [Localization.secondPhoneNumber]: 'Segundo número de teléfono',
      [Localization.cellPhone]: 'Teléfono celular',
      [Localization.paid]: 'Pagadas',
      [Localization.all]: 'Todas',
      [Localization.notPaid]: 'No pagadas',
      [Localization.isFlexible]: 'Es estricto el pago en cuotas',
      [Localization.isFlexibleDescription]: 'Si esta activo, esto quiere decir, que los pagos deben de ser iguales o mayor a el total de la cuota, \n si esta inactivo, los pagos son flexibles (puede pagar menor o mayor a la cuota)',
      [Localization.productsManagement]: 'Administración de productos',
      [Localization.payments]: 'Pagos',
      [Localization.paidDateFrom]: 'Fecha de pago desde',
      [Localization.paidDateTo]: 'Fecha de pago hasta',
      [Localization.installmentPayments]: 'Cuotas',
    },
  },
}))
